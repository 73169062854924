import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/mdxPageLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Download`}</h2>
    <p><a parentName="p" {...{
        "href": "/5f33f0a0810edb5f93e7ef3cafcfb5ef/WP8AppbarIcons.zip"
      }}>{`Download here!`}</a></p>
    <h2>{`Last update`}</h2>
    <p>{`14-12-2012`}</p>
    <h2>{`About`}</h2>
    <p>{`The Windows Phone 8 development team added a set of 36 icons to the `}<abbr parentName="p" {...{
        "title": "Software Development Kit"
      }}>{`SDK`}</abbr>{` to be used on the Application Bar by developers of external applications.`}</p>
    <p>{`This number seemed quite low to get things done, even more after seeing the other icons spread across the native Windows Phone 8 applications (like the “send” icon on the messages, the “attach” icon on the mail app, etc.)`}</p>
    <p>{`Thus, I now present you a collection of `}<strong parentName="p">{`99 extra icons`}</strong>{` in comparison to the 36 that comes on the `}<abbr parentName="p" {...{
        "title": "Software Development Kit"
      }}>{`SDK`}</abbr>{` (please click on the image below to get a full size visualization of the collection)`}</p>
    <p><a parentName="p" {...{
        "href": "/e1ec830159233a8c34da949bc93db0c8/Windows-Phone-8-Application-Bar-Icons.png"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "480px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7CAAAOwgEVKEqAAAABSklEQVQoz3WQy27DIBBFSRMKmPfMgMEYHDlSs8z//13VoErd9KxGdzP3HtZ7TynFGLdt894TEQAYY4gIEWdORDFGIrrf7ymlmRMRO47DOSel1Fp772utYwwAqLWGN713a60xxnuPiJxz/yalxLz3SqllWQAAEbXWzrnWmlJqXdfjOPZ9F0I8Ho/zPKWUYwxENMZorRkR1Vp779u2IeJsqJSKMXrvrbUAsCzL3xwRAcA5x1prOed938cYvfdSChE559Z1JaI5PoQAAHM8AKSUcs4hBLau6+ygtZ4yQgjW2hBCrdU5V0qZpVprU2qMMeeMiD/CWmulFGstIu77XmvlnFtrz/P8+uX5fM7j9XqllIwxMUZmrdNazz+lFGOMlJJzrpSa/mOMn79wzoUQt9vt4w2TUgohlkUTUc6Zc365XK7X6+2NEEJKyf7hGyQaJLR+PFBtAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Windows Phone 8 Application Bar Icons",
            "title": "Windows Phone 8 Application Bar Icons",
            "src": "/static/f7066e3ebb0ada617a7bb979550f5eef/e85cb/Windows-Phone-8-Application-Bar-Icons-thumb.png",
            "srcSet": ["/static/f7066e3ebb0ada617a7bb979550f5eef/e9ff0/Windows-Phone-8-Application-Bar-Icons-thumb.png 180w", "/static/f7066e3ebb0ada617a7bb979550f5eef/f21e7/Windows-Phone-8-Application-Bar-Icons-thumb.png 360w", "/static/f7066e3ebb0ada617a7bb979550f5eef/e85cb/Windows-Phone-8-Application-Bar-Icons-thumb.png 480w"],
            "sizes": "(max-width: 480px) 100vw, 480px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`Most of these icons are the original ones that show up in the Windows Phone 8 apps (extracted from the application resources), others where simply modified to get the correct measures (changing the “canvas” to 76x76, the right size for this type of icons).`}</p>
    <p>{`The icons are here free of charge, all I ask is that you please link to this page and not directly to the file! ;)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      